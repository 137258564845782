
































import useUser from "@/use/user";
import { computed, defineComponent, reactive } from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    users: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root, emit }) {
    const { getRoleName } = useUser({ root });

    const state = reactive({
      search: "",
      loading: false,
      items: [],
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      headers: [
        { value: "select", text: "", width: 1, sortable: false },
        { value: "name", text: "Imię i nazwisko", sortable: false },
        { value: "vehicles", text: "Liczba pojazdów", sortable: false },
        { value: "accessories", text: "Liczba akcesoriów", sortable: false },
      ],
    });

    const model = computed({
      get: () => props.value,
      set: (value) => emit("input", value),
    });

    const fetch = () => {
      state.loading = true;

      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get("inventory/user", {
          params: {
            search: state.search || undefined,
            page: state.options?.page || undefined,
            itemsPerPage: state.options?.itemsPerPage || undefined,
          },
        })
        .then(({ data: { users, total } }) => {
          state.items = users;
          state.total = total;
        })
        .catch(() => {
          state.items = [];
          state.total = 0;
        })
        .finally(() => (state.loading = false));
    };

    watchDebounced([() => state.search, () => state.options], fetch, {
      deep: true,
      immediate: true,
      debounce: 500,
      maxWait: 5000,
    });

    return { getRoleName, state, model };
  },
});
